<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import Swal from "sweetalert2";
import { companies } from "@/config/api/companies";
import MemberModal from "@/components/companies/members/member-modal";
/**
 * Product Detail Component
 */
export default {
  components: {
    Layout,
    PageHeader,
    MemberModal,
  },
  data() {
    return {
      memberId: null,
      memberInfo: [],
      companyInfo: [],
      title: "Member Info",
      selectedMember: null,
      loading: false,
      items: [
        {
          text: "Company Info",
          to: { path: `/company-info?id=${this.$route.query.cid}` },
        },
        {
          text: "Member Info",
          active: true,
        },
      ],
    };
  },
  created() {
    if (this.$route.query.mid) {
      this.memberId = this.$route.query.mid;
      console.log(this.memberId);
      this.loadInfo();
    }
  },
  methods: {
    loadInfo() {
      if (this.$route.query.cid) {
        const api = companies.getById;
        api.id = this.$route.query.cid;
        this.loading = true;
        this.generateAPI(api)
          .then((res) => {
            this.companyInfo = res.data.company;
            this.memberInfo = this.companyInfo.company_members.filter(
              (member) => (member._id === this.memberId ? member : null)
            )[0];
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    showMemberActionModal() {
      this.$bvModal.show("action-Member");
    },
    showMemberViewModal() {
      this.$bvModal.show("action-MemberView");
    },
    showActionModal() {
      this.$bvModal.show("action-Member");
    },
    hideActionModal() {
      this.$bvModal.hide("action-Member");
    },
    editMember(data) {
      this.selectedMember = data;
      this.showActionModal();
    },
    deleteMember(data) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons
        .fire({
          title: "Are you sure, you want to delete this member?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#34c38f",
          cancelButtonColor: "#f46a6a",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.value) {
            // this.loading = true;
            const api = companies.deleteMember;
            api.id = data._id;
            api.data = { ...data };
            this.generateAPI(api)
              .then(() => {
                this.$bvToast.toast("Member deleted successfully!", {
                  title: "Delete",
                  variant: "success",
                  solid: true,
                });
              })
              .catch((err) => {
                this.$bvToast.toast("Member is not deleted", {
                  title: "Delete",
                  variant: "danger",
                  solid: true,
                });
                console.error(err);
                // this.loadInfo();
              })
              .finally(async () => {
                await this.$router.push({
                  path: "/company-info",
                  query: { id: this.$route.query.cid },
                });
                this.loading = false;
              });
          }
        });
    },
  },
  watch: {},
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div v-if="loading">
      <div style="text-align: center" class="mt-5 mb-5">
        <pulse-loader color="#505d69" :loading="loading"></pulse-loader>
      </div>
    </div>
    <div class="row" v-else>
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div style="text-align: end; float-right" class="mb-2 mb-2">
              <b-button
                class="mr-1"
                type="button"
                @click="deleteMember(memberInfo)"
                pill
                variant="danger"
              >
                Delete
              </b-button>

              <!-- <router-link
                :to="{
                  path: `/edit-member`,
                  query: { id: memberInfo._id },
                }"
                > -->
              <b-button
                style="cursor: pointer"
                @click="editMember(memberInfo)"
                pill
                variant="primary"
              >
                Edit
              </b-button>
              <!-- </router-link -->
              <!-- > -->
            </div>

            <div class="row">
              <div class="col-xl-5">
                <div class="product-detail">
                  <div class="row">
                    <div class="col-md-11 col-9">
                      <div class="tab-content" id="v-pills-tabContent">
                        <div
                          class="tab-pane fade show active"
                          id="product-1"
                          role="tabpanel"
                        >
                          <div class="col-md-6">
                            <div class="mt-4 mt-md-0">
                              <img
                                class="rounded-circle avatar-xl"
                                alt="200x200"
                                src="@/assets/mawj/logo.svg"
                                data-holder-rendered="true"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end product img -->
              </div>
              <div class="col-xl-7">
                <div class="mt-4 mt-xl-3">
                  <a v-if="memberInfo.name" href="#" class="text-primary">{{
                    memberInfo.name
                  }}</a>
                  <h5 class="mt-1 mb-3">{{ memberInfo.name }}</h5>
                  <h5 class="mt-1 mb-3">{{ memberInfo.email }}</h5>

                  <hr class="my-4" />
                  <div class="row">
                    <div class="col-md-6">
                      <h5 class="font-size-14">Basic Info :</h5>
                      <ul class="list-unstyled product-desc-list">
                        <li>
                          Status:
                          {{
                            memberInfo.deletedAt
                              ? "Inactive (Deleted)"
                              : "Active"
                          }}
                        </li>
                        <li v-if="memberInfo.is_verified" class="verified_icon">
                          Verified:
                          <i
                            style="color: green; font-size: 1.2rem"
                            class="ri-checkbox-circle-fill"
                          ></i>
                        </li>
                        <li
                          v-if="!memberInfo.is_verified"
                          class="verified_icon"
                        >
                          Verified:
                          <i
                            class="ri-close-circle-line verified_icon"
                            style="color: red; font-size: 1.2rem"
                          ></i>
                        </li>
                        <li>
                          Featured: {{ memberInfo.featured ? "Yes" : "No" }}
                        </li>
                        <li>Position: {{ memberInfo.position }}</li>
                        <li v-if="memberInfo.city">
                          City: {{ memberInfo.city.name }}
                        </li>
                        <li v-if="memberInfo.country">
                          Country: {{ memberInfo.country.name }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
    <!-- end row -->
    <MemberModal
      :currentCompany="companyInfo"
      :selectedMember="selectedMember"
      v-on:reloadData="loadInfo"
      v-on:resetModal="selectedMember = null"
      v-on:closeModal="hideActionModal"
    />
  </Layout>
</template>

<style scoped>
.verified_icon {
  display: flex;
  align-items: center;
  column-gap: 5px;
}
</style>